import React, {useState, useEffect} from 'react'
import apiConfig from '../../config/apiConfig';
import {Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link';

function Shop() {
    const [data, setData] = useState([]);
    useEffect(async () => {
        const response = await fetch(apiConfig.shopItemsApi, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        // console.log(response);
        const result = await response.json();
        if(result.data) {
            setData(result.data);
        }
    }, [])
    return (
        <>
        {
            data ?
            <section className="upcoming-games-area upcoming-games-bg pt-120 pb-80" id="shop">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center mb-70">
                                <h2 className="text-dark">Shop</h2>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="row justify-content-center">
                    {
                        data?.length > 0 ? 
                        
                            data.map((item) => {
                                return (
                                    <div className="col-lg-4 col-md-6" key={item.id}>
                                        <div className="upcoming-game-item mb-40">
                                            <div className="upcoming-game-head">
                                            <div className="shop-item-title mb-10 w-100">
                                                <h4><a href="/#">{ item.title }</a></h4>
                                            </div>
                                            <div className="shop-item-price">
                                                <h5 className="d-block">{ item.price }</h5>
                                            </div>
                                            </div>
                                            <p>{ item.description }</p>
                                            <div className="upcoming-game-thumb">
                                            <img src={ item.thumb } alt="shop_item" />
                                            <div className="upcoming-game-cart">
                                                {/* <HashLink to={`/shop/${item.id}/order#orderform`} className="btn transparent-btn"><i className="fas fa-shopping-basket" />Order Now</HashLink> */}
                                                <a href={`https://m.me/101190158112425?ref=${item.item_code}`} className="btn transparent-btn"><i className="fas fa-shopping-basket" />Order Now</a>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <p>There is no items</p>
                    }
                    </div>
                </div>
            </section>
            :
            <p>Loading...</p>
        }
        </>
    )
}

export default Shop