import React,{  useEffect } from 'react'
import $ from "jquery";
import 'magnific-popup';
import { Link } from 'react-router-dom';

const HightlightArea = () => {
  return (
    <section className="live-streaming-area">
    <div className="container">
      <div className="row">
        <div className="col-sm-6">
          <div className="hf-section-title mb-50">
            <h4 className="title">Highlights</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-8 col-lg-7">
          <div className="live-stream-item mb-30">
            <a href="https://www.facebook.com/share/r/1YXrQ2ZeivvQFQJC/?mibextid=oFDknk" target="_blank">
                <div className="thumb">
                    <img src="assets/img/images/achievement_2.jpg" alt="" />
                    <div className="popup-video" ><img src="assets/img/icon/w_play.png" alt="" /></div>
                </div>
            </a>
            <div className="content">
              <div className="live-stream-meta">
                <ul>
                  <li>June 14, 2024</li>
                </ul>
              </div>
              <h4 className="title"><a href="https://www.facebook.com/share/r/1YXrQ2ZeivvQFQJC/?mibextid=oFDknk" target="_blank">MWI Myanmar Qualifier Grand Final</a></h4>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-5">
          <div className="row">
            <div className="col-lg-12 col-md-6">
              <div className="live-stream-item style-two mb-30">
              <a href="https://www.facebook.com/share/r/KyfFGuTEJinKkUYJ/?mibextid=oFDknk" target="_blank">
                    <div className="thumb">
                        <img src="assets/img/images/achievement_1.jpg" alt="" />
                    </div>
                    <div className="content">
                        <div className="popup-video"><img src="assets/img/icon/w_play.png" alt="" /></div>
                        <div className="live-stream-meta">
                            <ul>
                            <li>June 16, 2024</li>
                            </ul>
                        </div>
                        <h4 className="title"><a href="https://www.facebook.com/share/r/KyfFGuTEJinKkUYJ/?mibextid=oFDknk" target="_blank">Ooredoo Gameverse Grand Final</a></h4>
                    </div>
                </a>
              </div>
            </div>
            <div className="col-lg-12 col-md-6">
              <div className="live-stream-item style-two mb-30">
                <a href="https://www.facebook.com/share/r/ufR8Kf5eHUrDEGFF/?mibextid=oFDknk" target="_blank">
                    <div className="thumb">
                        <img src="assets/img/images/achievement_3.jpg" alt="" />
                        </div>
                        <div className="content">
                        <div className="popup-video"><img src="assets/img/icon/w_play.png" alt="" /></div>
                        <div className="live-stream-meta">
                            <ul>
                                <li>2023</li>
                            </ul>
                        </div>
                        <h4 className="title"><a href="https://www.facebook.com/share/r/ufR8Kf5eHUrDEGFF/?mibextid=oFDknk" target="_blank">GG Gaming Fest Grand Final</a></h4>
                    </div>
                    </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
}

export default HightlightArea