import React from 'react';
import Footer from '../footer/Footer';
import HeaderTwo from '../header/HeaderTwo';
import SliderTwo from './SliderTwo';
import MatchList from './MatchList';
import MostPerformedArea from './MostPerformedArea'
import AchievementArea from './AchievementArea'
import HomeThirdSection from './HomeThirdSection'
import HightlightArea from './HightlightArea';

function Index(){
    
	return(
    

        <>
         {/* header-area */}
          <HeaderTwo/>
       
      
        {/* main-area */}
        <main>
            <SliderTwo/>
            
            <MatchList/>
            <HomeThirdSection/>
            <AchievementArea/>
            <MostPerformedArea/>
            <HightlightArea/>
        </main>
        {/* main-area-end */}
        <Footer/>
        </>
	)
}
	
export default Index