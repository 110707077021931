import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom"
import apiConfig from '../../config/apiConfig';

function BlogContent() {
    const params = useParams();
	const slug = params.slug;
    const [blog, setBlog] = useState();
    useEffect(async () => {
        const response = await fetch(apiConfig.blogDetailApi.replace(":slug", slug));
        const result = await response.json();
        setBlog(result.data);
    }, [])
    return (
        <section className="blog-area primary-bg pt-120 pb-175">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="blog-list-post blog-details-wrap">
                            <div className="blog-list-post-thumb">
                                <img src={blog?.thumb} alt="" />
                            </div>
                            <div className="blog-list-post-content">
                                <h2>{blog?.title}</h2>
                                <div className="blog-meta">
                                    <ul>
                                        <li>{blog?.formatted_created_at}</li>
                                    </ul>
                                </div>
                                <p>{blog?.body}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlogContent