import React from 'react'

function PartnerInvitation() {
  return (
	<div className="community-area primary-bg pt-60 pb-80">
        <div className="container">
          <div className="row justify-content-center">
            
            <div className="col-xl-6 col-lg-6">
                <div className="section-title text-center mb-60 mt-50">
                    <h2 className="text-dark">BE OUR <span>PARTNER</span></h2>
                </div>
                <p className="pb-25">Explore our network of remarkable partners. Join us on this incredible journey of collaboration and growth. Become a Partner today!We are always looking for opportunities to grow together with collaborative partners and sponsors.</p>
                {/* <a href="mailto:info@netangels.gg" className="btn btn-style-two">Contact Us</a> */}
            </div>
            <div className="col-xl-6 col-lg-6">
                <img className="mw-100" src="assets/img/images/partner.png" alt="partner_invitation" />
            </div>
          </div>
        </div>
        <div className="community-bg-shape"><img src="assets/img/images/community_bg_shape.png" alt="" /></div>
      </div>
  )
}

export default PartnerInvitation