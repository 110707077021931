import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Breadcrumb from './Breadcrumb'
import OrderForm from './OrderForm'
import Footer from '../../components/footer/Footer'

function Order() {
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
	<OrderForm/>
	</main>
	<Footer/>
	</>
  )
}

export default Order