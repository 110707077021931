import React from 'react'
import Breadcrumb from "./Breadcrumb";
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import PartnerInvitation from './PartnerInvitation';
import PartnerListArea from './PartnerListArea';

function Partner() {
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
    <PartnerInvitation/>
    <PartnerListArea/>
	</main>
	<Footer/>
	</>
  )
}

export default Partner