import React from 'react'

function InfoArea({teamMember}) {
    
    return (
        <section className="game-single-area pt-120 pb-180">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <div className="game-single-content game-overview-content">
                        <div className="upcoming-game-head">
                        <div className="uc-game-head-title">
                            <h4>Meet {teamMember?.team_member_name}</h4>
                        </div>
                        </div>
                        <p>{teamMember?.team_member_description}</p>
                        {/* <div className="row mt-55 mb-55">
                        <div className="col-lg-12">
                            <div className="game-single-title mb-15">
                            <h4>achievement</h4>
                            </div>
                            <div className="overview-history-content">
                            <p>Donec orci enim, bibendum a augue aliquet cursus quam. Pellentesque pulvinar, elit at condimentum dictum, sapien auctor
                                tortoris vulputate sapien tortor et velit. Sed nulla nisi, congue eu quam vel, molestie grvida ipsum. Curabi lacus vitae
                                tellus lacinia pretium.</p>
                            <div className='col-lg-12 d-flex justify-content-center'>
                                <div className="overview-history-list col-lg-8">
                                <ul>
                                    <li>
                                    <div className="overview-list-thumb">
                                        <img src="assets/img/images/overview_history_thumb01.jpg" alt="" />
                                    </div>
                                    <div className="overview-list-content">
                                        <h4><a href="/#">MPC Season 1</a></h4>
                                    </div>
                                    <div className="overview-list-price">
                                        <h4>2nd Runnerup</h4>
                                    </div>
                                    </li>
                                    <li>
                                    <div className="overview-list-thumb">
                                        <img src="assets/img/images/overview_history_thumb02.jpg" alt="" />
                                    </div>
                                    <div className="overview-list-content">
                                        <h4><a href="/#">MPC Season 2</a></h4>
                                    </div>
                                    <div className="overview-list-price">
                                        <h4>2nd Runnerup</h4>
                                    </div>
                                    </li>
                                </ul>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div> */}
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoArea