import React from 'react'

function VisionMission() {
  return (
	 <section className="latest-match-area latest-match-bg pt-115 pb-90">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="section-title title-style-three white-title text-center mb-70">
                <h2>Our <span>vision</span> & <span>mission</span></h2>
                
              </div>
            </div>
          </div>
          <div className="row align-items-center pb-90">
            <div className="col-xl-4 col-lg-5">
                <h2 className="title">Our Vision</h2>
               
                <p>We aim to actively engage and complete on the international stage as a distinguished female MLBB team, showcasing the strength of female players in Myanmar.</p>
             
            </div>
            <div className="col-xl-8 col-lg-7">
              <div className="chair-discount-img">
                <img src="assets/img/images/vision.png" alt="" />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="chair-discount-img">
                <img src="assets/img/images/mission.png" alt="" />
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
                <h2 className="title">Our Mission</h2>
               
                <p> Building on our past success, exemplified by a triumphant trophy at GG Gaming Fest, our ongoing commiment is to dominate the female mlbb esports scene. We strive to emerge as the foremost female MLBB team locally and globally.</p>
             
            </div>
          </div>
        </div>
      </section>
  )
}

export default VisionMission