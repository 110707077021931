import React from 'react'
import Breadcrumb from "./Breadcrumb";
import InfoArea from "./InfoArea"
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import apiConfig from '../../config/apiConfig';

function MemberDetail() {
	const [teamMember, setTeamMember] = useState();
    const params = useParams();
	const memberId = params.id;
    useEffect(async () => {
        const response = await fetch(apiConfig.teamMemberDetailApi.replace(':id', memberId));
        const result = await response.json();
        setTeamMember(result.data);
    }, [])
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb teamMember={teamMember}/>
	<InfoArea teamMember={teamMember}/>
	</main>
	<Footer/>
	</>
  )
}

export default MemberDetail