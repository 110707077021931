import React from 'react'
import { useState, useEffect } from 'react';
import apiConfig from '../../config/apiConfig';

function UpcomingMatch() {
    const [matches, setMatches] = useState();
    useEffect(async () => {
        const response = await fetch(apiConfig.matchesApi);
        const result = await response.json();
        setMatches(result.data);
    }, [])
    
    return (
        <section className="my-match-area my-match-bg pb-120">
            <div className="container custom-container">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center mb-75">
                            <h2>UPCOMING & RECENT<span> MATCHES</span></h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="my-match-wrap">
                            {
                                matches && 
                                matches.map((match) => {
                                    return (
                                        <div className="my-match-box-wrap wow fadeInDown" data-wow-delay=".2s" key={match.id}>
                                            <img src="assets/img/bg/my_match_box.png" alt="" className="match-box-bg" />
                                                <ul>
                                                    <li>
                                                        <div className="my-match-team match-team-logo">
                                                            <div className="team-one">
                                                                <a href="/#"><img src={match.opponent_team_logo} alt="" /></a>
                                                                <h4 className="text-center mt-2">{match.opponent_team_point}</h4>
                                                            </div>
                                                            <div className="vs">
                                                                <img src="assets/img/team/match_vs02.png" alt="" />
                                                            </div>
                                                                <div className="team-one match-team-logo">
                                                                <a href="/#"><img src="assets/img/logo/logo_only.png" alt="" /></a>
                                                                <h4 className="text-center mt-2">{match.team_point}</h4>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="my-match-info">
                                                            <a href="https://www.facebook.com/profile.php?id=61551723667175&mibextid=ZbWKwL" target="_blank" rel="noreferrer" className="live-btn">Live MATCHES</a>
                                                            <h5>{match.match_date}</h5>
                                                            <span>({match.match_time} MMT)</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <a href={match.match_link} target="_blank" rel="noreferrer" className="watch-stream"><i className="fas fa-podcast" /> watch stream</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UpcomingMatch