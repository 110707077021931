import React,{ useEffect } from 'react'
import WOW from "wowjs";

function AboutInner() {
  useEffect(()=>{
    new WOW.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: false,
      live: true,
      loop:Infinity,
    }).init();
  
    },[])
  return (
	<section className="inner-about-area fix">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6 order-0 order-lg-2">
              <div>
                {/* <img src="assets/img/images/inner_about_img01.png" className="wow fadeInRight" data-wow-delay=".3s" alt="" /> */}
                <img src="assets/img/images/vision.png" className="wow fadeInLeft w-100 mb-3" data-wow-delay=".6s" alt="" />
                {/* <img src="assets/img/images/inner_about_img03.png" className="wow fadeInUp" data-wow-delay=".6s" alt="" /> */}
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="section-title title-style-three mb-25">
                <h2>WHO <span>WE</span> ARE</h2>
              </div>
              <div className="inner-about-content">
                <h5>Net Angels | Female MLBB Team</h5>
                <p>Net Angels was founded in 22 September 2023.</p>
                <p>Net Angels goal is to gain international recognition as a girl's Mobile Legends Bang Bang team representing MYANMAR</p>
                {/* <a href="/#" className="btn btn-style-two">Contact Us</a> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="inner-about-shape"><img src="assets/img/images/medale_shape.png" alt="" /></div> */}
      </section>
  )
}

export default AboutInner