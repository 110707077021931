import React from 'react'
import HeaderTwo from '../../components/header/HeaderTwo'
import Breadcrumb from './Breadcrumb'
import Shop from './Shop'
import EventRegisteration from './EventRegisteration'
import Footer from '../../components/footer/Footer'

function EventAndShop() {
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
	<Shop/>
	<EventRegisteration/>
	</main>
	<Footer/>
	</>
  )
}

export default EventAndShop