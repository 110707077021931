import React from 'react'

const AchievementOverlayContent = ({title, teamPoint, opponentPoint, opponentLogo, opponentTeamName, date, gameData}) => {
  return (
    <div className="featured-game-content featured-game-overlay-content">
                      
        <h4 className="text-white">{title}</h4>
        <div className="featured-game-meta achievement-hover-content">
            <div className="custom-result-row d-flex justify-content-center p-2 w-100">
                <div className="border-right border-white w-50">Net Angels <img className="height-30" src="assets/img/logo/logo_only.png" alt="net_angels_team_logo"/><span className="px-1">{teamPoint}</span></div>
                <div className="w-50"><span className="px-1">{opponentPoint}</span> <img className="height-30" src={opponentLogo} alt="net_angels_team_logo"/> {opponentTeamName}</div>
            </div>
            <div className="custom-result-row p-2 w-100">
                <div className="d-inline">{date}</div>
            </div>
            {
                gameData.map((game, index) => {
                    return (
                        <div className="custom-result-row p-2 w-100" key={index}>
                            { game.teamWin && <i className="fa fa-solid w-0 fa-check text-success position-absolute right-60 line-height-12"/> }
                            <div className="d-inline px-2">{game.name}</div>
                            { game.otherWin && <i className="fa fa-solid w-0 fa-check text-success position-absolute left-60 line-height-12"/> }
                        </div>
                        
                    )
                })
            }
        </div>
    </div>
  )
}

export default AchievementOverlayContent