import React from 'react'
import {Link} from 'react-router-dom'
import AchievementOverlayContent from './AchievementOverlayContent'



function Achievement() {

  
  return (
	 <section className="featured-game-area position-relative pt-115 pb-90">
            <div className="featured-game-bg" />
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-6 col-lg-8">
                  <div className="section-title text-center mb-70">
                    <h2 className="text-dark">Recent <span>Achievements</span></h2>
                   
                  </div>
                </div>
              </div>
              <div className="row featured-active">
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/achievement_2.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4>MWI Myanmar Qualifiers</h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-calendar" />
                        <span>June 2024</span>
                      </div>
                    </div>
                    {/* <div className="featured-game-content featured-game-overlay-content">
                      
                      <h4 className="text-white">MWI Myanmar Qualifiers</h4>
                      <div className="featured-game-meta achievement-hover-content">
                        <div className="custom-result-row d-flex justify-content-center p-2 w-100">
                          <div className="border-right border-white w-50">Net Angels <img className="height-30" src="assets/img/logo/logo_only.png" alt="net_angels_team_logo"/><span className="px-1">3</span></div>
                          <div className="w-50"><span className="px-1">1</span> <img className="height-30" src="assets/img/logo/falcon_daisies_logo.png" alt="net_angels_team_logo"/> Falcon Daisies</div>
                        </div>
                        <div className="custom-result-row p-2 w-100">
                          <div className="d-inline">June 14, 2024</div>
                        </div>
                        <div className="custom-result-row p-2 w-100">
                          <i className="fa fa-solid w-0 fa-check text-success position-absolute right-60 line-height-12"/>
                          <div className="d-inline px-2">Game 1</div>
                        </div>
                        <div className="custom-result-row p-2 w-100">
                          <i className="fa fa-solid w-0 fa-check text-success position-absolute right-60 line-height-12"/>
                          <div className="d-inline px-2">Game 2</div>
                        </div>
                        <div className="custom-result-row p-2 w-100">
                          <div className="d-inline px-2">Game 3</div>
                          <i className="fa fa-solid w-0 fa-check text-success position-absolute left-60 line-height-12"/>
                        </div>
                        <div className="custom-result-row p-2 w-100">
                          <i className="fa fa-solid w-0 fa-check text-success position-absolute right-60 line-height-12"/>
                          <div className="d-inline px-2">Game 4</div>
                        </div>
                      </div>
                    </div> */}
                    <AchievementOverlayContent
                    title={"MWI MYANMAR QUALIFIERS"} 
                    teamPoint={3} 
                    opponentPoint={1} 
                    opponentLogo={"assets/img/logo/falcon_daisies_logo.png"}
                    opponentTeamName={"Falcon Daisies"} 
                    date={"JUNE 14, 2024"}
                    gameData={
                        [
                            {
                                name: "Game 1",
                                teamWin: true,
                                otherWin: false
                            },
                            {
                                name: "Game 2",
                                teamWin: true,
                                otherWin: false
                            },
                            {
                                name: "Game 3",
                                teamWin: false,
                                otherWin: true
                            },
                            {
                                name: "Game 4",
                                teamWin: true,
                                otherWin: false
                            },
                        ]
                    }
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/achievement_1.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4>Ooredoo Game Verse</h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-calendar" />
                        <span>June 2024</span>
                      </div>
                    </div>
                    <AchievementOverlayContent
                    title={"Gameverse Mission 2024"} 
                    teamPoint={3} 
                    opponentPoint={0} 
                    opponentLogo={"assets/img/logo/falcon_daisies_logo.png"}
                    opponentTeamName={"Falcon Daisies"} 
                    date={"JUNE 16, 2024"}
                    gameData={
                        [
                            {
                                name: "Game 1",
                                teamWin: true,
                                otherWin: false
                            },
                            {
                                name: "Game 2",
                                teamWin: true,
                                otherWin: false
                            },
                            {
                                name: "Game 3",
                                teamWin: true,
                                otherWin: false
                            },
                        ]
                    }
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 grid-item">
                  <div className="featured-game-item mb-30">
                    <div className="featured-game-thumb">
                      <img src="assets/img/images/achievement_3.jpg" alt="" />
                    </div>
                    <div className="featured-game-content">
                      <h4>GG Gaming FEST</h4>
                      <div className="featured-game-meta">
                        <i className="fas fa-calendar" />
                        <span>2023</span>
                      </div>
                    </div>
                    <AchievementOverlayContent
                    title={"GG Gaming Fest"} 
                    teamPoint={2} 
                    opponentPoint={1} 
                    opponentLogo={"assets/img/logo/burmese_ghouls.png"} 
                    opponentTeamName={"Burmese Ghouls"}
                    date={"NOVEMBER 5, 2023"}
                    gameData={
                        [
                            {
                                name: "Game 1",
                                teamWin: false,
                                otherWin: true
                            },
                            {
                                name: "Game 2",
                                teamWin: true,
                                otherWin: false
                            },
                            {
                                name: "Game 3",
                                teamWin: true,
                                otherWin: false
                            }
                        ]
                    }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default Achievement