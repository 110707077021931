import React from 'react';
import Slider from 'react-slick';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";



function SliderTwo() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll:1,
    center:false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  }

  // useEffect(()=>{
	// new WOW.WOW({
  //   boxClass: 'wow',
	// 	animateClass: 'animated',
	// 	offset: 0,
	// 	mobile: false,
	// 	live: true,
  //   loop:Infinity,
  // }).init();

  // },[])

  return (


<section className="slider-area">
            <Slider className="slider-active" {...settings}>
              <div className="single-slider slider-bg slider-style slider-style-one">
                <div className="container-fluid container-full-padding">
                  <div className="row">
                    <div className="col-xl-6 col-lg-7 col-md-11">
                      <div className="slider-content ">
                        <Fade up delay={.4000}>
                        <h6>Introduction</h6>
                        </Fade>
                        
                        <Fade up delay={.5000}> 
                        <h1>NET Angels</h1>
                        </Fade>
                        <Fade up delay={.5000}>
                        <p>NET Angels is a premier esports organization established in September 2023, with a vision to dominate both domestic and international stages. Our pride and cornerstone is the NET Angels MLBB Women’s Team, which made an extraordinary impact by clinching three prestigious domestic titles within its very first year of competition.</p>
                        </Fade >
                       
                        {/* <Fade up delay={.8000}>
                        <a href="/#" className="btn btn-style-two">READ MORE</a>
                        </Fade> */}
                      </div>
                    </div>
                  </div>
                </div>
                <Slide right delay={.4000}>
                <div className="slider-img"><img src="assets/img/slider/slider_img01.png" alt="" /></div>
                </Slide>
               <Slide left delay={.4000}>
               <div className="slider-img slider-img2 wow slideInRightS"><img src="assets/img/slider/slider_img02.png" alt="" /></div>
               </Slide>
                
              </div>
              <div className="single-slider slider-bg slider-style slider-style-two">
                <div className="container-fluid container-full-padding">
                  <div className="row">
                    <div className="col-xl-6 col-lg-7 col-md-11">
                      <div className="slider-content">
                        <Fade up delay={.4000}>
                        <h6>Introduction</h6>
                        </Fade>
                        <Fade up delay={.5000}>
                        <h1 >NET Angels</h1>
                        </Fade>
                        <Fade up delay={.5000}>
                        <p>In 2024, this talented team had the honor of representing Myanmar at the MLBB Women’s Invitational (MWI 2024), an esteemed part of the Esports World Cup. Competing against the world’s best, they achieved an impressive top 8 finish, solidifying their reputation on the global stage.</p>
                        </Fade>
                        {/* <Fade up delay={.8000}>
                        <a href="/#" className="btn btn-style-two">READ MORE</a>
                        </Fade> */}
                      </div>
                    </div>
                  </div>
                </div>
                <Slide right delay={.4000}>
                <div className="slider-img second-slider-img"><img src="assets/img/slider/shop_slider_img01.png" alt="" /></div>
                </Slide>
                <Slide left delay={.4000}>
                <div className="slider-img slider-img2 second-slider-img second-slider-img2"><img src="assets/img/slider/shop_slider_img02.png" alt="" /></div>
                </Slide>
                {/* <div className="slider-circle-shape"><img src="assets/img/slider/slider_circle.png" alt="" className="rotateme" /></div> */}
              </div>
              <div className="single-slider slider-bg slider-style slider-style-three">
                <div className="container-fluid container-full-padding">
                  <div className="row">
                    <div className="col-xl-6 col-lg-7 col-md-11">
                      <div className="slider-content">
                        <Fade up delay={.4000}>
                        <h6>Introduction</h6>
                        </Fade>
                        <Fade up delay={.5000}>
                        <h1 >NET Angels</h1>
                        </Fade>
                        <Fade up delay={.5000}>
                        <p>At NET Angels, our ambitions soar beyond national boundaries. We are committed to fostering excellence and are dedicated to pursuing even greater triumphs on the international scene, striving to leave an indelible mark in the world of esports.</p>
                        </Fade>
                        {/* <Fade up delay={.8000}>
                        <a href="/#" className="btn btn-style-two">READ MORE</a>
                        </Fade> */}
                      </div>
                    </div>
                  </div>
                </div>
                <Slide right delay={.4000}>
                <div className="slider-img second-slider-img"><img src="assets/img/slider/shop_slider_img01.png" alt="" /></div>
                </Slide>
                <Slide left delay={.4000}>
                <div className="slider-img slider-img2 second-slider-img second-slider-img2"><img src="assets/img/slider/shop_slider_img02.png" alt="" /></div>
                </Slide>
                {/* <div className="slider-circle-shape"><img src="assets/img/slider/slider_circle.png" alt="" className="rotateme" /></div> */}
              </div>
            </Slider>
          </section>
  )
}

export default SliderTwo