import React, {useState, useRef} from 'react';
import apiConfig from '../../config/apiConfig';

function ContactArea() {
    const [message, setMessage] = useState();
    const [resultMessage, setResultMessage] = useState();
    const formRef = useRef();

    const sendMessage = async (e) => {
        e.preventDefault();
        if(message?.name && message?.email && message?.message) {
            const response = await fetch(apiConfig.contactApi, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(message)
            });
    
            const result = await response.json();
            if(result.data) {
                setResultMessage("Message sent");
                formRef.current.reset();
                
            }else{
                alert("Something went wrong");
            }
        }
        
    }
    return (
        <section className="contact-area pt-120 pb-120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 pl-45">
                    <div className="section-title title-style-three mb-20">
                        <h2>CONTACT US </h2>
                    </div>
                    <div className="contact-info-list mb-40">
                        <ul>
                        <li><i className="fas fa-map-marker-alt" />Golden City, Yangon, Myanmar</li>
                        <li><i className="fas fa-envelope" />info@netangels.gg</li>
                        </ul>
                    </div>
                    <div className="contact-form">
                        <form ref={formRef}>
                            <textarea name="message" id="message" placeholder="Write Message..."  onChange = {(e) => setMessage({...message, message: e.target.value})} defaultValue={""} />
                            <div className="row">
                                <div className="col-md-6">
                                    <input type="text" onChange={(e) => setMessage({...message, name: e.target.value})} placeholder="Your Name" />
                                </div>
                                <div className="col-md-6">
                                    <input type="email" onChange = {(e) => setMessage({...message, email: e.target.value})} placeholder="Your Mail" />
                                </div>
                            </div>
                            <button onClick={sendMessage}>SUBMIT MESSAGE</button>
                            {
                                resultMessage && (
                                    <p className="mt-5 text-white bg-warning p-3">{resultMessage}</p>
                                )
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default ContactArea