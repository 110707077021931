const facebookLink = "https://www.facebook.com/profile.php?id=61551723667175&mibextid=ZbWKwL";
const xLink = "https://x.com/net_angels2023";
const youtubeLink = "https://m.youtube.com/channel/UCI4FLpoli9cHP2l2Q6AicEw";
const instagramLink = "https://www.instagram.com/net_angels?igsh=dmx3a2dzZG1uN293";

export {
    facebookLink,
    xLink,
    youtubeLink,
    instagramLink
}