import React, { useState, useEffect } from 'react'
import apiConfig from '../../config/apiConfig';


function TeamArea() {
	const [teamMembers, setTeamMembers] = useState();
    useEffect(async () => {
        const response = await fetch(apiConfig.playerTeamMembersApi);
        const result = await response.json();
        setTeamMembers(result.data);
    }, [])
	return (
		<section className="team-area team-bg">
			<div className="container custom-container">
				<div className="row">
					<div className="col-12">
					<div className="section-title text-center mb-60">
						<h2 className="text-light">MEET <span>PLAYER</span> TEAM</h2>
					</div>
					</div>
				</div>
				<div className="row justify-content-center">
					{
						teamMembers && 
						teamMembers.map((teamMember) => {
							return (
								<div className="col-xl-3 col-lg-4 col-md-6 col-sm-8" key={teamMember.id}>
									<div className="third-team-item text-center mb-30">
										<div className="third-team-img">
											<img src={teamMember.team_member_photo} alt="" />
										</div>
										<div className="third-team-content">
											<div className="main-bg" />
											<div className="hover-bg" />
											<h5><a href={"team/" + teamMember.id + "/detail"}>{teamMember.team_member_name}</a></h5>
											<span>{teamMember.team_member_role}</span>
										</div>
									</div>
								</div>
							)
						})
					}
				</div>
			</div>
		</section>
	)
}

export default TeamArea