import React, { useState, useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import apiConfig from '../../config/apiConfig';

function Blogarea() {
    const [blogs, setBlogs] = useState();
    const [paginationData, setPaginationData] = useState({
        'prevPage' :0,
        'nextPage' : 0,
        'currentPage' : 1
    });
    const location = useLocation();
    
    useEffect(async () => {
        
        const response = await fetch(apiConfig.blogsApi + "?page=" + paginationData.currentPage);
        const result = await response.json();
        setBlogs(result.data);
        const lastPage = parseInt(result.pagination.last_page);
        const firstPage = parseInt(result.pagination.first_page);
        
        var prevPage = paginationData.currentPage - 1;
        var nextPage = paginationData.currentPage + 1;
        if(paginationData.currentPage == firstPage) {
            prevPage = firstPage
        }

        if(paginationData.currentPage == lastPage) {
            nextPage = lastPage;
        }

        setPaginationData({
            ...paginationData, 
            prevPage: prevPage,
            nextPage: nextPage 
        })
        
    }, [paginationData.currentPage])

    return (
        <section className="blog-area primary-bg pt-120 pb-175">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        {
                            blogs &&
                            blogs.map((blog) => {
                                return (
                                    <div className="blog-list-post" key={blog.id}>
                                        <div className="blog-list-post-thumb">
                                            <Link to={"/blog-detail/" + blog.slug}><img src={blog.thumb} alt="" /></Link>
                                        </div>
                                        <div className="blog-list-post-content">
                                        <h2><Link to={"/blog-detail/" + blog.slug}>{blog.title}</Link></h2>
                                        <div className="blog-meta">
                                            <ul>
                                            <li>{blog.formatted_created_at}</li>
                                            </ul>
                                        </div>
                                        <p>{blog.limited_body}</p>
                                        </div>
                                        <div className="blog-list-post-bottom">
                                        <ul>
                                            <li><a href={"/blog-detail/" + blog.slug}>more reading<i className="fas fa-angle-double-right" /></a></li>
                                      
                                        </ul>
                                        </div>
                                    </div>  
                                )
                            })
                        }
                        <div className="pagination-wrap mt-60">
                            <ul>
                            <li><a href="#" onClick={(e) => {  e.preventDefault(); setPaginationData({...paginationData, currentPage: paginationData.prevPage}) } }>Prev</a></li>
                            <li><a href="#" onClick={(e) => {  e.preventDefault(); setPaginationData({...paginationData, currentPage: paginationData.nextPage}) } }>Next</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blogarea