import React, {useState, useRef, useEffect} from 'react';
import apiConfig from '../../config/apiConfig';

function EventRegisteration() {
    const [data, setData] = useState();
    const [resultMessage, setResultMessage] = useState();
    const [eventInfo, setEventInfo] = useState();
    const formRef = useRef();

    useEffect(async () => {
        const response = await fetch(apiConfig.eventInfoApi, {
            method: 'GET',
            headers: {
                "Content-Type": "application/json"
            }
        });
        // console.log(response);
        const result = await response.json();
        if(result.data) {
            setEventInfo(result.data);
        }
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(data?.name && data?.email && data?.phoneNumber) {
            const response = await fetch(apiConfig.eventRegisterApi, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });
    
            const result = await response.json();
            if(result.data) {
                setData({});
                setResultMessage("Register Successful");
                formRef.current.reset();
                
            }else{
                alert("Something went wrong");
            }
        }
        
    }
    return (
        <section className="contact-area pt-120 pb-120 event-bg" id="event">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-6 col-lg-8">
                        <div className="section-title text-center mb-70">
                            <h2 className="text-dark">Event</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    {
                        eventInfo ?
                        <>
                            <div className="col-lg-6">
                                <div className="section-title title-style-three mb-20">
                                    <h2>{ eventInfo.title } </h2>
                                </div>
                                <div className="mb-40">
                                    <p>{ eventInfo.description }</p>
                                </div>
                                <div className="contact-form">
                                    <form ref={formRef}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <input type="text" onChange={(e) => setData({...data, name: e.target.value})} placeholder="Your Name" />
                                            </div>
                                            <div className="col-md-6">
                                                <input type="email" onChange = {(e) => setData({...data, email: e.target.value})} placeholder="Your Mail" />
                                            </div>
                                            <div className="col-md-12">
                                                <input type="text" onChange = {(e) => setData({...data, phoneNumber: e.target.value})} placeholder="Your Phone Number" />
                                            </div>
                                        </div>
                                        <button onClick={handleSubmit} className = "mb-20">Register</button>
                                        {
                                            resultMessage && (
                                                <p className="mt-5 text-white bg-warning p-3">{resultMessage}</p>
                                            )
                                        }
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <img className="mw-100" src={eventInfo.thumb} alt="event-registeration" />
                            </div>
                        </>
                        :
                        <p>There is no event right now.</p>
                    }
                </div>
            </div>

        </section>
    )
}

export default EventRegisteration