import React from 'react';
import HeaderTwo from '../../components/header/HeaderTwo';
import Breadcrumb from './Breadcrumb';
import Footer from '../../components/footer/Footer';
import UpcomingMatch from './UpcomingMatch';

function Match() {
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
	<UpcomingMatch/>
	</main>
	<Footer/>
	</>
  )
}

export default Match
