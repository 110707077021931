import React,{ useState, useEffect } from 'react';
import Menu from "../Menu";
import apiConfig from '../../config/apiConfig';

export default function GamesManage() {

  const [matches, setMatches] = useState();
  useEffect(async () => {
      const response = await fetch(apiConfig.latestMatchesApi);
      const result = await response.json();
      setMatches(result.data);
  }, [])
  return (
    
	  <section className="game-manage-area pt-120 pb-120">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-8">
                <div className="section-title text-center mb-60">
                    <h2 className="text-dark">Upcoming And Recent <span>Matches</span></h2>
                </div>
                </div>
            </div>
            <div className="row justify-content-center">
                {
                    matches && 
                    matches.map((match) => {
                        return (
                            <div className="col-lg-4 col-md-6" key={match.id}>
                                <div className="coming-match-item mb-30">
                                    <div className="row text-center py-4 px-3">
                                        <div className="match-team-info col-4">
                                            <div className="match-team-logo">
                                            <a href="/#"><img src={match.opponent_team_logo} alt="" /></a>
                                            </div>
                                            <div className="match-team-name">
                                            <a href="/#">{match.opponent_team_name}</a>
                                            </div>
                                        </div>
                                    <div className="col-4 pt-3">
                                        <img src="assets/img/team/match_vs.png" alt="" />
                                    </div>
                                    <div className="match-team-info col-4">
                                        <div className="match-team-logo">
                                            <a href="/#"><img src="assets/img/logo/logo_only.png" alt="" /></a>
                                        </div>
                                        <div className="match-team-name">
                                            <a href="/#">Net Angels</a>
                                        </div>
                                    </div>
                                    </div>
                                    {
                                        match.is_completed ?  (
                                            <div className="coming-match-info">
                                                <div className="d-flex justify-content-between">
                                                    <h5>{match.opponent_team_point}</h5>
                                                    <h5>-</h5>
                                                    <h5>{match.team_point}</h5>
                                                </div>
                                                <div className="match-info-action">
                                                    <a href="https://www.youtube.com/watch?v=vRgmln3ralA" className="btn">view match</a>
                                                </div>
                                            </div>
                                        )
                                        :
                                        (
                                            <div className="coming-match-info">
                                                <h5>On {match.match_date}</h5>
                                                <h5>({match.match_time} MMT)</h5>
                                                <div className="match-info-action">
                                                    <a href={match.match_link} className="btn">view match</a>
                                                </div>
                                            </div>
                                        )
                                        
                                    }
                                    
                                    
                                </div>
                                </div>
                        )
                    })
                }
            </div>
        </div>
      </section>
  )
}
