import React from 'react';
import { facebookLink, instagramLink, xLink, youtubeLink } from '../../config/socialMediaConfig';

function Footer() {
  return (
	   <footer>
        <div className="footer-top footer-bg">
          
          <div className="container pt-90">
            <div className="row justify-content-between">
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="footer-logo mb-35">
                    <a href="/"><img src="assets/img/logo/logo.png" alt="" /></a>
                  </div>
                  <div className="footer-text">
                    <div className="footer-contact">
                      <ul>
                        <li><i className="fas fa-map-marker-alt" /> <span>Address : </span>Golden City, Yangon, Myanmar</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="footer-widget mb-50">
                  <div className="fw-title mb-35">
                    <h5>Follow us</h5>
                  </div>
                  <div className="footer-social">
                    <ul>
                      <li><a  href={facebookLink}><i className="fab fa-facebook-f" /></a></li>
                      <li><a  href={xLink}><i className="fab fa-twitter" /></a></li>
                      <li><a  href={youtubeLink}><i className="fab fa-youtube" /></a></li>
                      <li><a  href={instagramLink}><i className="fab fa-instagram" /></a></li>
                    </ul>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6">
                <div className="copyright-text">
                  <p>Copyright © 2024 <a  href="/#">Net Angels</a> All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
  )
}

export default Footer;
