import React from 'react'
import Breadcrumb from "./Breadcrumb";
import HeaderTwo from '../../components/header/HeaderTwo';
import Footer from '../../components/footer/Footer';
import InfoArea from '../memberdetails/InfoArea';
import TeamArea from './TeamPlayer';
import TeamMem from './TeamManagement';
import RetiredTeam from './RetiredTeam';

function MemberDetail() {
  return (
	<>
	<HeaderTwo/>
	<main>
	<Breadcrumb/>
	<TeamMem/>
	<TeamArea/>
	<RetiredTeam/>
	</main>
	<Footer/>
	</>
  )
}

export default MemberDetail