import React from 'react'
import {Link} from 'react-router-dom'


function HomeThirdSection() {


  
  return (
	<section className="cta-area cta-bg">
            <div className="container">
              <div className="row align-items-center justify-content-between">
                <div className="col-xl-5 col-lg-6">
                  <div className="cta-img">
                    <img src="assets/img/images/cta_img.png" alt="" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="cta-content">
                    <div className="section-title white-title mb-50">
                        <h2>Our social medias</h2>
                        <a href="https://www.facebook.com/profile.php?id=61551723667175&mibextid=ZbWKwL"><i className="fab fa-facebook-f rounded-circle fb-icon mr-3 facebook-icon"/></a>
                        
                        <a href="https://www.instagram.com/net_angels?igsh=dmx3a2dzZG1uN293"><i className="fab fa-instagram rounded-circle p-3 mr-3 instagram-icon"/></a>
                        <a href="https://x.com/net_angels2023"><i className="fab fa-twitter rounded-circle p-3 mr-3 twitter-icon"/></a>
                        <a href="https://www.youtube.com/channel/UCI4FLpoli9cHP2l2Q6AicEw"><i className="fab fa-youtube rounded-circle p-3 mr-3 youtube-icon"/></a>
                        <a href="https://www.tiktok.com/@net.angels?_t=8nUnJhNksHw&_r=1" className="tiktok-icon rounded-circle">
                          <img src="/assets/img/icon/tiktok-brands-solid.svg" /> 
                        </a>
                    </div>
                    {/* <div className="cta-btn">
                      <Link to="/contact" className="btn btn-style-two">CONTACT US</Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default HomeThirdSection