const baseUrl = "https://api.netangels.gg/api";
// const baseUrl = "http://127.0.0.1:8000/api";

const matchesApi = baseUrl + "/matches";
const latestMatchesApi = baseUrl + "/latest_matches";
const managementTeamMembersApi = baseUrl + "/team_members/management";
const playerTeamMembersApi = baseUrl + "/team_members/player";
const retiredMembersApi = baseUrl + "/team_members/retired_members";
const teamMemberDetailApi = baseUrl + "/team_members/:id/detail";
const partnersApi = baseUrl + "/partners";
const blogsApi = baseUrl + "/blogs";
const blogDetailApi = baseUrl + "/blogs/:slug/detail";
const contactApi = baseUrl + "/inbox";
const eventInfoApi = baseUrl + "/event";
const eventRegisterApi = baseUrl + "/register-event";
const shopItemsApi = baseUrl + "/items";
const orderApi = baseUrl + "/order";

export default {
    matchesApi,
    latestMatchesApi,
    managementTeamMembersApi,
    playerTeamMembersApi,
    teamMemberDetailApi,
    partnersApi,
    blogsApi,
    blogDetailApi,
    contactApi,
    eventInfoApi,
    eventRegisterApi,
    shopItemsApi,
    orderApi,
    retiredMembersApi
}