import React from 'react'
function MostPerformedArea() {

  
  return (
	 <section className="just-gamers-area just-gamers-bg pt-115 pb-80">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-10">
                  <div className="section-title white-title mb-70">
                    <h3 className="text-center">Our Most Performed Players</h3>
                    <p></p>
                  </div>
                  <div className="just-gamers-list">
                    <ul>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/images/kell.jpg" className="w-100" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Kell</h5>
                          <p>GG Gaming Fest. 2023 (Finals MVP)</p>
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/images/vanilla.jpg" className="w-100" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Vanilla</h5>
                          <p>MESL Pro Series 2024 (Finals MVP)</p>
                        </div>
                      </li>
                      <li>
                        <div className="just-gamers-list-icon">
                          <img src="assets/img/images/licca.jpg" className="w-100" alt="" />
                        </div>
                        <div className="just-gamers-list-content fix">
                          <h5>Licca</h5>
                          <p>GameVerse mission 2024 (Finals MVP)</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-6 d-none d-lg-block">
                  <div className="just-gamers-img">
                    <img src="assets/img/images/just_gamers_img.png" alt="" className="just-gamers-character" />
                    {/* <div className="just-gamers-circle-shape">
                      <img src="assets/img/images/gamers_circle_line.png" alt="" />
                      <img src="assets/img/images/gamers_circle_shape.png" alt="" className="rotateme" />
                    </div> */}
                    <img src="assets/img/images/just_gamers_chart.png" alt="" className="gamers-chart-shape" />
                  </div>
                </div>
              </div>
            </div>
          </section>
  )
}

export default MostPerformedArea