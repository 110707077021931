import React, { useState, useEffect } from 'react'
import apiConfig from '../../config/apiConfig';

function PartnerListArea() {
    const [partners, setPartners] = useState();
    useEffect(async () => {
        const response = await fetch(apiConfig.partnersApi);
        const result = await response.json();
        setPartners(result.data);
    }, [])
    return (
        <div className="primary-bg pt-60 pb-150 bg-dark">
            <div className="section-title text-center mb-100 mt-50">
                <h2>OUR <span>PARTNERS</span></h2>
            </div>
            <div className="container">
                {
                    partners && 
                    partners.map((partner) => {
                        return (
                            <div className="row justify-content-center mb-5" key={partner.id}>
                                <div className="col-xl-3 col-lg-6 col-md-12 col-6">
                                    <img className="mr-3 img-fluid" src={partner.partner_logo} alt={partner.partner_name + "_logo"}/>
                                </div>
                                <div className="col-xl-9 col-lg-6 col-md-12 mt-5">
                                    <h4 className="mb-30">{partner.partner_name}</h4>
                                    <p className="mb-50">{partner.partner_description}</p>
                                    <a href={partner.partner_link} className="btn btn-style-two float-right">Visit Mighty Star</a>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default PartnerListArea